import ApplicationController from "./application_controller"

import Raty from "raty-js"

export default class extends ApplicationController {

    connect () {
        this.raty = new Raty()
        console.log("RatingsController#connect")
    }
}