import ApplicationController from "./application_controller";


import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input", "pretitle", "posttitle" ]

    connect() {
        console.log("rr!")


        this.tomselect = new TomSelect(this.inputTarget, {
            placeholder: "Titel (optional)",
            searchable: true,
            create: false,
            maxItems: 2,
            multiple: true,
            plugins: {
                remove_button:{
                    title: 'x',
                }
            },
        })


    }

    update() {

    }

    disconnect() {
        this.tomselect.destroy(this.inputTarget)
    }
}