Application.loadTenantCustomizations = () ->

  $('.primary').addClass('btn btn-primary')

  $('.informed_assent .form-group').css( "color", "red" )  if ( $('.field_with_errors > #applicant_terms_accepted').length > 0 )

  $('.navbar-burger').click ->
  # Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $('.navbar-burger').toggleClass 'is-active'
    $('.navbar-menu').toggleClass 'is-active'
    return



