import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "title", "characterCount" ]

    initialize() {
        this.update()
    }

    update() {
        var count = this.titleTarget.value.length
        if (count == 1) {
            var message = "1 Zeichen"
        } else {
            var message = `${count} Zeichen`
        }

        this.characterCountTarget.textContent = message

        if (count > 60) {
            this.characterCountTarget.classList.add("text-danger")
        } else {
            this.characterCountTarget.classList.remove("text-danger")
        }
    }

    submit(event) {
        var count = this.bodyTarget.value.length
        if (count > 60) {
            event.preventDefault()
        }
        this.element.reset()
    }
}