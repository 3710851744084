require('bootstrap-select/js/bootstrap-select.js');

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "input" ]

    connect() {
        $(this.inputTarget).selectpicker()
    }

    disconnect() {
        $(this.inputTarget).selectpicker("destroy")
    }
}