import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static get targets() {
        return ['value', 'input']
    }

    display(event) {

        const fileName = event.target.value.split('\\').pop();
        this.valueTarget.innerHTML = fileName;

    }
}